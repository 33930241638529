import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { CursorContent } from '../../store';
import { Wrapper, List, Item } from './style';

const ProjectList = ({ category, items }) => {
  const cursorContentDispatch = useContext(CursorContent.Dispatch);

  return (
    <Wrapper>
      Project List —{' '}
      <List>
        {items
          ? items.map(item =>
              item.project ? (
                <Item key={item.id}>
                  <Link
                    to={`/${category ? category.slug : 'archive'}/${
                      item.project.slug
                    }`}
                    onMouseMove={() => {
                      if (!item.project.thumbnail) return;
                      cursorContentDispatch({
                        type: 'setActiveType',
                        activeType: 'projectListThumbnail',
                      });
                      cursorContentDispatch({
                        type: 'setContentByType',
                        contentType: 'projectListThumbnail',
                        content: item.project.thumbnail,
                      });
                    }}
                    onMouseLeave={() => {
                      cursorContentDispatch({
                        type: 'setActiveType',
                        activeType: null,
                      });
                      cursorContentDispatch({
                        type: 'setContentByType',
                        contentType: 'projectListThumbnail',
                        content: null,
                      });
                    }}
                  >
                    {item.label}
                  </Link>
                </Item>
              ) : (
                <Item key={item.id}>{item.label}</Item>
              )
            )
          : null}
      </List>
    </Wrapper>
  );
};

export default ProjectList;
