import styled from 'styled-components';

export const Wrapper = styled.div``;

export const List = styled.ul`
  display: inline;
`;

export const Item = styled.li`
  display: inline;

  &:after {
    content: ', ';
  }

  &:last-child:after {
    content: '';
  }

  a {
    cursor: none;
  }
`;
