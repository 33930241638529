import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import ProjectName from '../../components/ProjectName';
import ProjectList from '../../components/ProjectList';

const Category = ({ pageContext, data }) => {
  const { metaData } = pageContext;
  const category = get(data, 'contentfulProjectCategory');

  return (
    <>
      <Helmet title={`${category.name} | ${metaData.siteName}`} />
      <ProjectList category={category} projects={category.projects} />
    </>
  );
};

export default Category;

export const pageQuery = graphql`
  query CategoryQuery($slug: String!) {
    contentfulProjectCategory(slug: { eq: $slug }) {
      id
      name
      slug
      projects {
        id
        name
        client
        slug
      }
    }
  }
`;
